.main {
  padding: 2rem 0;
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.container {
  padding: 0 calc(100vw / 15);
}
.container.head {
  width: 55%;
}
.head {
  h1 {
    font-size: 3rem;
    color: #182232;
    .highlight {
      color: #006c71;
    }
    .highlight.black {
      font-weight: 600;
      font-size: 1.2em;
    }
    margin-bottom: 1rem;
  }
}
.culture {
  h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
  }

  padding: 15% 0 5% 0;
  background-image: url("../../Assets/Images/our-culture-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  .key-points {
    margin-top: 2rem;
    width: 60%;
    .key-point-card {
      width: 40%;
      img {
        width: 4rem;
        padding-bottom: 0.5rem;
      }
      h3 {
        color: #fff;
        padding-bottom: 0.5rem;
      }
      P {
        color: #fff;
        font-size: 0.8rem;
      }
      margin: 1.5rem 0;
    }
  }
  .image {
    width: 35%;
  }
}

.careers {
  margin: 2rem 0;
  h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .career-card {
    padding: 1rem;
    .head {
      .date{
        font-weight: 600;
      }
      h3 {
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: #182232;
      }
      display: flex;
      justify-content: space-between;
    }
    width: 70%;
    border: 1px solid #b7b7b7;
    border-radius: 1rem;
    button {
      padding: 0;
      background: none;
      color: #006c71;
      font-size: 1.2em;
      margin-left: 1rem;
    }
    button:hover {
      color: #008888;
      border: none;
    }
    p {
      font-size: 0.9rem;
    }
  }
}
.job-modal {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  border-radius: 1vw;
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: fit-content;
    }
  }
  h1 {
    font-weight: 600;
    color: #182232;
    font-size: 2rem;
  }
  h3 {
    margin-bottom: 0.4rem;
    margin-top: 0.75rem;
    font-weight: 500;
  }
  p {
    font-size: 0.8rem;
    ul {
      margin-left: 2em;
      li {
        list-style-type: disc;
      }
    }
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 2.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding-top: 13vh;
    .head {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1 {
      font-size: 2rem;
      text-align: center;
    }
  }
  .culture {
    padding-top: 45%;
    .key-points {
      width: 100%;
      flex-wrap: wrap;
      .key-point-card {
        width: 100%;
      }
    }
    .image {
      display: none;
    }
  }
  .careers {
    .career-card {
      width: 100%;
      .head {
        align-items: start;
        margin-bottom: 0.5rem;
        h3 {
          margin-bottom: 0.2rem;
          font-size: 1.5rem;
        }
      }
    }
  }
  .job-modal {
    width: 90%;
    padding: 2rem;
    top: 80%;
    button{
        margin-top: 0;
        width: 30%;
        padding: .5rem;
    }
    p{
        font-size: .6rem;
    }
    h1{
        font-size: 1.2rem;
    }
    h3{
        font-size: 1rem;
    }
    button{
        font-size: .5rem;
    }
    .close-btn
    img{
        width: 1.5rem;
    }
  }
}
