@font-face {
    font-family: "Ananda";
    src: url("../Assets/fonts/ananda_2/Ananda\ Black\ Personal\ Use.ttf");
}
@font-face {
    font-family: "paradise";
    src: url("../Assets/fonts/birds_of_paradise/Birds\ of\ Paradise\ ╕\ PERSONAL\ USE\ ONLY.ttf");
}
@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@500&display=swap');


#about-us{
    /* background-color: red; */
   background-image: url("../Assets/Images/aboutusBg.svg");
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
}




#about-us header{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    /* margin-top: 2.5vw; */
    padding: 9vw 0vw 0vw 0vw;
    line-height: 2vw;

}

#about-us header h1{
    font-size: 4vw;
    font-family: "Geomatrix-Bold";
}
#about-us header p{
    font-size: 1.4vw;
    font-family: "Geomatrix-Medium";
    padding: 2vw 10vw;
    color: rgba(60, 60, 60, 1);
}

.about-director{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
   padding: 8vw 0vw;
}
.about-director .image{
   width: 30vw;
   /* display: flex;
   justify-content: flex-end; */
   /* background-color: red; */
}
.about-director .image img{
   /* margin-left: 5vw; */

}

.about-content .desc{
    padding-top: 2vw;
   font-size: 1.4vw;
   text-align: justify;
    color:  black;
    line-height: 2vw;
    /* font-family: "Ananda"; */
    font-family: 'Alkatra', cursive;


}

.about-director .about-content{
    width: 50vw;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items:baseline;
    text-align: left;
    padding: 0vw 5vw;
    flex-direction: column;
}
.about-content h2{
    font-family: "Geomatrix-Bold";
    font-size: 2.5vw;
}

.about-content .Position{
    font-family: "Geomatrix-Medium";
    color: rgba(0, 108, 113, 1) !important;
    font-size: 1.5vw !important;
    padding: 0.6vw 0vw;
}

.about-content p{
    font-family: "Geomatrix-Medium";
    color: rgba(60, 60, 60, 1);
    font-size: 1.1vw;

}


.our-commitment{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    /* padding-top: 5vw; */
}

.our-commitment h1{
    font-size: 4vw;
    font-family: "Geomatrix-Bold";
}


.our-commitment p{
    font-size: 1.4vw;
    font-family: "Geomatrix-Medium";
    padding: 2vw 12vw;
    color: rgba(60, 60, 60, 1);
}

.desc span img{
    width: 2vw;
    /* margin: 0vw 0.2vw; */
}
.desc span .Closing-quotes{
margin-bottom: -0.5vw;
}



@media screen and (max-width : 768px){
    .desc span img{
        width: 6vw;
        /* margin: 0vw 0.2vw; */
    }
    #about-us{
        padding-top: 24vw;
    }
    #about-us header h1 {
        font-size: 6vw;
    }
    .about-director{
        padding: 20vw 0vw;
        flex-direction: column;
    }
    #about-us header p , .our-commitment p {
        font-size: 3.2vw;
        font-family: "Geomatrix-Medium";
        padding: 4vw 6vw;
        text-align: justify;
        color: rgba(60, 60, 60, 1);
        line-height: 3.8vw;
    }
    .about-content h2 {
        font-family: "Geomatrix-Bold";
        font-size: 5.5vw;
    }

    .about-content .Position {
        font-family: "Geomatrix-Medium";
        color: rgba(0, 108, 113, 1) !important;
        font-size: 3vw !important;
        padding: 0.8vw 0vw;
    }
    .about-director .about-content{
        padding: 0vw 1vw;
        width: 80vw;
    }
    .about-content p {
        font-family: "Geomatrix-Medium";
        color: rgba(60, 60, 60, 1);
        font-size: 2.8vw;
        line-height: 3.5vw;
        text-align: justify;
    }
    .our-commitment h1{
        font-size: 6vw;
    }

    .about-director .image{
        width: 80vw;
    }
    .about-content .desc {
        padding-top: 3vw;
        font-size: 4.4vw;
        text-align: justify;
        color: black;
        line-height: 5.5vw;
    }
    #about-us header{
        padding: 9vw 0vw 2vw 0vw;
    }
    .HomeClients{
        padding: 5vw 0vw 2vw 0vw;
    }
}