.ServiceHeader {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 38vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../Assets//Images/dataBackgroundImage.svg");
}
.ServiceHeader h1 {
  font-size: 5vw;
}
.DataContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-top: 0.8vw;
}
.DataContent header {
  width: 55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  padding: 0vw 5vw;
  /* background-color: red; */
}
.DataContent header h1 {
  color: rgba(24, 34, 50, 1);
  font-size: 4vw;
  margin-right: auto;
  font-family: 'Geomatrix-Bold',sans-serif;
  margin-bottom: 0.6vw;
}
.DataContent header p {
  font-weight: 500;
  font-family: 'Geomatrix-Bold';
  color: rgba(60, 60, 60, 1);
  font-size: 1.2vw;}

.Approch {
  margin-top: 1vw;
  /* background-color: black; */
  padding-bottom: 5vw;
}
.Approch header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Approch header h1 {
  color: rgba(24, 34, 50, 1);
    font-size: 4vw;
    /* margin-right: auto; */
    font-family: 'Geomatrix-Bold',sans-serif;
    padding-bottom: 2.5vw;
}


.boxContainerTop {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.boxContainerTop .box {
  /* border: 1px solid black; */
  width: 20vw;
  padding: 0.5vw 2vw;
}
.boxContainerTop .box h1 {
  font-size: 1.6vw;
  color: rgba(0, 0, 0, 1);
  font-family: 'Geomatrix-Bold';
  font-weight: 700;
}
.boxContainerTop .box p {
  font-size: 1vw;
    font-weight: 500;
    color: rgba(60, 60, 60, 1);
    font-family: 'Geomatrix-Bold';
    margin-top: 0.5vw;
}
.boxCollector {
  display: flex;
  margin-left: auto;
  margin-right: 15vw;
}

.ApproachImage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.boxCollector .box:last-child {
  margin-right: -5vw;
}
.boxCollector .box:first-child {
  /* background-color: red; */
  margin-right: 3vw;
}
.middleBox {
  margin-right: 3vw;
}

.ApproachImage img {
  width: 70vw;
  /* padding: 50vw 0vw; */
}

.boxCollectorBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.boxCollectorBottom .box {
  width: 25vw;
  padding: 0.5vw 2vw;
}
.boxCollectorBottom .box h1 {
  font-size: 1.6vw;
  color: rgba(0, 0, 0, 1);
  font-family: 'Geomatrix-Bold';
  font-weight: 700;
}
.boxCollectorBottom .box p {
  font-size: 1vw;
  font-weight: 500;
  color: rgba(60, 60, 60, 1);
  font-family: 'Geomatrix-Bold';
  margin-top: 0.5vw;
  padding: 0vw 2vw;
}
.boxCollectorBottom .box:first-child {
  margin-left: -15vw !important;
}
.boxCollectorBottom .middleBox {
  margin-right: -1.6vw;
}

.Solutions {
  padding: 2.5vw 8vw 10vw 6vw;
}
.Solutions header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-bottom: 2vw;
}
.Solutions header h1 {
  color: rgba(24, 34, 50, 1);
  font-size: 3.5vw;
}
.solutionImgContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.solutionImgContainer .solutionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 26vw;
  padding: 0vw 0vw 2vw 0vw;
  /* border: 1px solid black; */
  border-radius: 1vw;
  box-shadow: 2px 2px 100px 4px rgba(0, 0, 0, 0.25);
}
.solutionBox img {
  width: 25vw;
  /* background-color: red; */
  padding: 2vw 0vw;
}

.Solutioncontent {
  display: flex;
  justify-content: justify;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.Solutioncontent h1 {
  font-size: 1.8vw;
  padding: 0.6vw 0vw;
}
.Solutioncontent p {
  font-weight: 500;
  display: flex;
  justify-content: justify;
  font-size: 0.7;
  /* background-color: red; */
  padding: 0vw 1.5vw;
}
.solutionImageTwo {
  width: 21vw !important;
}

/* mobile view from here... */
.mobile-heading{
  display: none;
}
.mobile-contentImg{
  display: none;
}
.Mobile-Approch{
  display: none;
}

@media screen and (max-width: 768px) {
  .Approch{
    display: none;
  }
  .Mobile-Approch{
    display: block;
  }

  #Service{
    padding-top: 20vw;
  }
  .mobile-heading{
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12vw 0vw 7vw 0vw;
  }
  .mobile-contentImg{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .ServiceHeader h1{
    font-size: 12vw;
    font-family: "Geomatrix-Bold";
  }

  .DataContent header h1{
    display: none;
  }
  .DataContent{
    display: flex;
    flex-direction: row-reverse;
    flex-direction: column;
  }
  .contentImg{
    display: none;
  }
  .DataContent header p{
    font-weight: 500;
    font-size: 4.3vw;
    font-family: "Geomatrix-Medium";
  }
   
  .DataContent header{
    width: 100vw;
    text-align: justify;
  }
  .Solutions{
    padding: 3vw 0vw 5vw 0vw;
  }
  .solutionImgContainer .solutionBox{
    width: 45vw;
    height: 65vw;
  }
  .solutionBox img{
    width: 24vw;
    padding: 6vw 0vw;
  }

  .solutionImageTwo{
    width: 25vw !important;
  }

  .Solutioncontent h1{
    font-size: 3vw;
    padding: 0.6vw 0vw;
    font-weight: 700;
  }

  .Solutioncontent p{
    font-weight: 400;
    font-size: 2.5vw;
    text-align: justify;
    padding: 0vw 2vw;
    font-family: 'Geomatrix-Medium';
  }

  .Solutions header h1{
    padding-left: 3vw;
    padding-bottom: 1.5vw;
    font-size: 5.5vw;
    font-family: "Geomatrix-Bold";
  }

  .solutionImgContainer .solutionBox{
    box-shadow: 2px 2px 100px 4px rgb(0 0 0 / 14%);
  }

  .Mobile-Approach-cards{
    margin-top: 4vw;
    padding-bottom: 8vw;
  }

  .Mobile-Approch header{
    display: flex;
    padding-top: 8vw;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Mobile-Approch header h1{
    font-size: 9vw;
    font-family: "Geomatrix-Bold";
    color: rgba(24, 34, 50, 1);
  }


  .Left-Content , .Right-Content{
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100vw;
    padding: 6vw 4vw;
  }
  .Left-Content .content-Container{
    width: 60vw;
    /* background-color: orange; */
    /* padding: 1.2vw; */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0vw 1vw 0vw 2vw;
  }
  .Right-Content .content-Container{
    width: 60vw;
    /* background-color: orange; */
    /* padding: 1.2vw; */
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 0vw 1vw 0vw 2vw;
  }


  .Left-Content .content-Container .content , .Right-Content .content-Container .content{
    padding: unset;
  }
  .Left-Content .img img , .Right-Content .img img {
    /* padding: 2vw; */
    width: 20vw;
  }
  .content-Container h1 {
    font-size: 4.2vw;
    line-height: 4.5vw;
    color: rgba(24, 34, 50, 1);
    font-family: 'Geomatrix-Medium';
  }
  .Right-Content .content-Container p{
    font-size: 2.2vw;
    color: rgba(60, 60, 60, 1);
    padding: 1vw 0vw 0vw 3vw !important;
    font-weight: 500;
  }
  .Left-Content .content-Container p{
    font-size: 2.4vw;
    color: rgba(60, 60, 60, 1);
    padding: 1vw 3vw 0vw 0vw !important;
    font-weight: 500;
    font-family: "Geomatrix-Medium";
  }


  .mobile-heading  h1{
    color: rgba(24, 34, 50, 1);
    font-family: "Geomatrix-Bold",sans-serif;
    font-size: 9vw;
  }

}
