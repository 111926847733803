/* This css is connected with Home.jsx and navbar.jsx compo */


@font-face {
  font-family: "Geomatrix-Light";
  src: url("../Assets/fonts/geometrix/GeomatrixLight-9Yrm2.ttf");
}

@font-face {
  font-family: "Geomatrix-Bold";
  src: url("../Assets/fonts/geometrix/GeomatrixBold-6YBZ1.ttf");
}

@font-face {
  font-family: "Geomatrix-Medium";
  src: url("../Assets/fonts/geometrix/GeomatrixMedium-1G4Zv.ttf");
}

@font-face {
  font-family: "Geomatrix-Thin";
  src: url("../Assets/fonts/geometrix/GeomatrixThin-BWPYw.ttf");
}

@font-face {
  font-family: "Geomatrix-Normal";
  src: url("../Assets/fonts/geometrix/Geomatrix-8MYjn.ttf");
}


.Video{
  background-color: white !important;
}

/* drop down styling */
.dropbtn {
  background-color: white;
  color: black;
  width: 8vw;
  /* padding: 0.5vw; */
  cursor: pointer;
  font-size: 1.2vw;
  font-family: "Geomatrix-Medium", sans-serif;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown {
  /* position: relative;
  display: inline-block; */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 10vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  margin-top: -0.5vw;
  opacity: 0;
}

.dropdown-content a {
  color: black;
  padding: 0.5vw 0.5vw;
  text-decoration: none;
  display: block;
  font-size: 1.1vw;
}



.dropdown-content a:hover {
  background-color: #182232;
  color: white;
  /* color: black; */
  font-size: 1.2vw;
  /* transition-duration: 0.8s; */
  /* transition-timing-function: ease-in-out; */
  font-weight: 500;
}



.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  /* transition-duration: 0.s; */
}

.dropdown:hover .dropbtn {
  background-color: white;
  color: #006c71;
  border: 2px solid white;
}



.dropdown .dropbtn img {
  width: 0.8vw;
  margin-left: 0.6vw;
}



.dropdown .dropbtn .up-arrow{
  display: none;
}


.dropdown:hover .up-arrow{
  display: inline;
  z-index: 999;
}

.dropdown:hover .down-arrow{
  display: none;
  /* z-index: 999; */
}

.Universal {
  /* height: 100vw; */
  /* display: flex;
  flex-direction: column; */
}
/* Navbar editing from heree .... */
.Navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  /* border-bottom: 1px solid rgb(201, 201, 201); */
  /* height: 5vw; */
  transition: height 0.3s;
  padding: 1rem 2vw;
}
.Navbar .logo img {
  width: 10vw;
  margin-left: 3vw;
}
.NavLink {
  display: flex;
  justify-content: flex-start;
  /* width: 100vw; */
  align-items: center;
  text-align: center;
  /* background-color: yellow; */
  margin-right: auto;
  margin-left: auto;
}

.NavLink .links,
.contact .links {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0vw 0.8vw;
  width: 8vw;
  padding: 0.5vw;
  cursor: pointer;
  font-size: 1.2vw;
  transition: background-color 0.3s, height 0.2s, font-weight 0.2s;
  font-family: "Geomatrix-Medium", sans-serif;
  color: rgba(24, 34, 50, 1);
  font-size: 800;
}
.Drop-down {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.1vw;
  color: rgba(24, 34, 50, 1);
  border: none;
  font-family: "Geomatrix-Medium", sans-serif;
  font-size: 500;
  width: 8vw;
  border-radius: unset !important;
}

.option {
  color: red;
  background-color: orange;
  /* border: 1px solid black !important; */
  padding: 5vw;
}

/* making mobile view content navbar display to none ... */

.mobileView {
  display: none;
}

/* Home styling over here */
.HomeContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding-top: 6vw;
  /* border-bottom: 1px solid red; */
  margin-bottom: 8vw;
  /* margin-top: 5vw; */
  /* background-color: red; */
  /* background-image: url("../Assets/Images/Homebg2.svg"); */
  background-image: url("../Assets/Images/aboutusBg.svg");
  /* background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
    background-size: 145vw;
    background-repeat: no-repeat;
    /* background-size: contain; */
    /* background-position: left; */
}
.HomeContainer .Quotation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50vw;
  /* background-color: red; */
  font-family: "Geomatrix-Bold", sans-serif;
  /* font-size: 500;
    margin-bottom: auto; */
  /* background-color: yellow; */
  /* border-left: 0.5vw solid gray; */
}
.Quotation div {
  display: flex;
  justify-content: flex-end;
  text-align: right !important;
  align-items: end;
  flex-direction: column;
  padding: 0vw 2vw;
  width: 36vw;
  /* background: red; */
}
.Quotation span {
  /* height: 6vw;
  width: 0.4vw;
  background-color: gray; */
}
.Quotation h1 {
  /* background-color: red; */
  /* margin-right: auto; */
  line-height: 4.2vw;
  font-family: "League Spartan", sans-serif;
  font-weight: 800;
}
.Quotation .Connecting {
  font-size: 5.5vw;
  /* letter-spacing: 0vw; */
  font-weight: 800;
  padding: 1.2vw 0vw;
  /* background: linear-gradient(90deg, #579d9f, #121729 20%); */
  color: black;
/* 
  background-color: #006C71;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.Quotation .TextStoke {
  color: black;
  font-size: 6vw;
  padding: 2vw 0vw;
  font-family: "Geomatrix-Bold" !important;
  -webkit-text-stroke: 3px black;
  -webkit-text-fill-color: transparent;
}
#video {
  width: 29vw;
  border: 0.5vw solid white !important;

}
.HomeABout {
  /* background-color: red; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
}

.HomeABout .aboutImg {
  /* background-color: gray; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.aboutImg img {
  width: 30vw;
}

.aboutContent {
  display: flex;
  justify-content: justify;
  align-items: left;
  text-align: left;
  width: 50%;
  flex-direction: column;
  padding: 0vw 10vw 0vw 2vw;
  /* background-color: red; */
}
.aboutContent p {
  font-weight: 500;
  line-height: 2.2vw;
  color: rgba(60, 60, 60, 1);
  font-family: "Geomatrix-Medium", sans-serif;
  font-size: 1.3vw;
  padding-bottom: 2vw;
}
.aboutContent h1 {
  margin-bottom: 1.6vw;
  color: rgba(24, 34, 50, 1);
  font-size: 2.6vw;
  font-family: "Geomatrix-Bold", sans-serif;
}

/* home service editing from here... */
.HomeServices {
  margin-top: 7.5vw;
}
.HomeServices header,
.HomeTech header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.HomeServices header p,
.HomeTech header p {
  color: #006c71;
  font-weight: 700;
  margin-bottom: 0.2vw;
  font-size: 2.6vw;
  font-family: "Geomatrix-bold",sans-serif;
}
.HomeServices header h1,
.HomeTech header h1 {
  color: rgba(24, 34, 50, 1);
  font-size: 2.6vw;
  font-family: "Geomatrix-Bold", sans-serif;
}
.HomeServices .box {
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  padding: 1rem 0.5rem;
  border-radius: 0.4vw;
  margin: 0vw 6vw;
  /* background-color: whitesmoke; */
  width: 16vw;
  box-shadow: 2px 2px 100px 4px rgba(0, 0, 0, 0.25);
}

.HomeServices .box:hover {
  border-top: 5px solid #182232;
  transition-duration: 0.2s;
}
.serviceImages {
  display: flex;
  justify-content: center;
  margin-top: 4vw;
}
.HomeServices .box img {
  margin: 0.7vw 0vw;
  width: 5vw;
}
.box p {
  font-size: 1.2vw;
  font-weight: 600;
}
.box .link {
  font-size: 0.9vw;
  color: #006c71;
  margin-top: 0.2vw;
  cursor: pointer;
  align-items: center;
  text-align: center;
  display: flex;
  /* background: red; */
  justify-self: center;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto;
  justify-content: center;
}

.box .link img {
  width: 0vw;
  margin-left: 0.5vw;
  transition: width 0.2s;
}
.HomeServices .box:hover .link img {
  width: 0.8vw;
}

/* Technologies Editing from here... */
.HomeTech {
  margin-top: 7.5vw;
}
.techImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0vw 3.5vw;
}

.techImg .tech-content {
  width: 46vw;
  padding: 2.5vw;
  font-family: "Geomatrix-Medium", sans-serif;
  line-height: 2vw;
  font-size: 1.4vw;
  text-align: justify;
  word-spacing: 0.2vw;
  /* background-color: red; */
}

.techImg img {
  /* background-color: red; */
  width: 45vw;
}

/* Home blog Editing from here.. */
.HomeBlog {
  /* margin-top: 3vw; */
  padding: 3vw 8vw 3vw 8vw;
}

.HomeBlog header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  margin-bottom: 3vw;
}
.HomeBlog header h1 {
  font-size: 2.1vw;
}
.HomeBlog header p {
  color: #006c71;
  font-weight: 700;
  font-family: "Geomatrix-bold",sans-serif;
  font-size: 2.8vw;
}

.blogBox {
  /* border: 1px solid black; */
  width: 20vw;
  border-radius: 1vw;
  /* margin: 0vw 5vw; */
}
.blogBox:hover {
  box-shadow: 2px 2px 100px 4px rgba(0, 0, 0, 0.25);
  transition-duration: 0.4s;
  color:  black !important;
}
.blogBox .time {
  display: flex;
  justify-content: flex-start;
}
.blogImg {
  width: 20vw;
}
.blogBox img {
  border-top-right-radius: 1vw;
  border-top-left-radius: 1vw;
}
.time img {
  margin: 0vw 0.4vw 0vw 0vw;
}
.time {
  color: #006c71;
  font-weight: 700;
  padding: 0vw 0.5vw;
}
.content {
  padding: 0.4vw 1vw;
}

.content h1 {
  margin: 0.4vw 0vw;
  font-size: 1.2vw;
  font-family: "Geomatrix-Normal", sans-serif;
}
.content p {
  font-size: 0.8vw;
  display: flex;
  justify-content: justify;
  font-family: "Geomatrix-Bold", sans-serif;
}
.content .link {
  margin: 0.8vw 0vw;
  color: #006c71;
  cursor: pointer;
  /* font-weight: 500; */
}
.blogImagecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* our clients design from here.... */
.HomeClients {
  margin-top: 2vw;
}
.HomeClients header {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.HomeClients header h1 {
  color: rgba(24, 34, 50, 1);
  font-size: 2.6vw;
  font-family: "Geomatrix-Bold", sans-serif;
}

.imageContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 5vw 0 3vw 0;
}
.imageContainer .imageBox {
  /* border: 1px solid gray; */
  border-radius: 0.5vw;
  width: 15vw;
  margin-bottom: 5vw;
  padding: 0.4vw;
}

.imageContainer .imageBox img {
  padding: 0.2vw;
  width: 10vw;
}

.got-to-top {
  position: fixed;
  z-index: 99;
  bottom: 2rem;
  right: 2rem;
  /* opacity: 0; */
  transition: 0.2s ease;
  cursor: pointer;
}
.got-to-top.active {
  opacity: 1;
}
.got-to-top.light svg path {
  fill: #fff;
}
.About-mobile-heading {
  display: none;
}
/* media qureies for phone and mobile .. */
@media screen and (max-width: 768px) {
  .HomeBlog header p {
    color: #006c71;
    font-weight: 700;
    font-family: "Geomatrix-bold",sans-serif;
    font-size: 5.8vw;
  }
  .Quotation div {
    display: flex;
    justify-content: flex-end;
    text-align: right !important;
    align-items: end;
    flex-direction: column;
    /* padding: 0vw 2vw; */
    width: 80vw;
    /* background: red; */
  }
  .Quotation .Connecting {
    font-size: 10.5vw;
    letter-spacing: 0vw;
    font-weight: 800;
    padding-bottom: 1vw;
    /* background: linear-gradient(90deg, #579d9f, #121729 20%); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: black;
  }
  .Quotation .TextStoke {
    color: black;
    font-size: 10vw;
    padding: 2vw 0vw;
    font-family: "Geomatrix-Bold" !important;
    letter-spacing: 1.3vw;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: transparent;
  }

  .mobileView {
    display: block;
  }

  .logo-title {
    /* background-color: red !important; */
    width: 60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .logo-title .CapNxt-logo img {
    width: 45vw;
    margin-left: 5vw;
  }

  .Call-Logo {
    background-color: black;
    margin-right: -30%;
    width: 10vw;
    border-radius: 100vw;
    height: 10vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .Call-Logo a img {
    /* width: 5vw; */
  }

  .logo-mobile .Nav {
    display: flex;
  }
  .Navbar {
    display: none;
  }
  /* header */
  header {
    display: block;
  }
  header nav {
    padding: 0.5rem 3.6vw;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    position: fixed;
    width: 100vw;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px #00000042;
  }
  nav .title {
    font-size: 2rem;
    font-family: "Jost", sans-serif;
    /* -webkit-font-feature-settings:"smcp"; */
    /* background: linear-gradient(
      90deg,
      var(--gradient-color-one),
      var(--gradient-color-two) 80%
    ); */
    background-color: #ff762d;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  #menuToggle {
    display: block;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    align-self: center;
  }
  #menuToggle a {
    text-decoration: none;
    color: rgba(0, 0, 0, 1);
    transition: color 0.3s ease;
    font-size: 5.4vw;
    /* margin-left: 2.5vw; */
  }
  .NavImages {
    padding-right: 5vw;
  }

  #menuToggle input {
    display: block;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 7; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
  #menuToggle span {
    display: block;
    width: 1.75rem;
    height: 0.2rem;
    margin-bottom: 5px;
    position: relative;
    background-color: black;
    border-radius: 4px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.6s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }
  #menuToggle span:nth-child(3) {
    width: 1.2rem;
  }

  #menuToggle span:nth-child(4) {
    width: 0.6rem;
    margin-bottom: 0;
  }
  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
* Transform all the slices of hamburger
* into a crossmark.
*/
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
  }

  /*
* But let's hide the middle one.
*/
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.8, 0.2);
  }
  #menuToggle input:checked ~ span:nth-last-child(2) {
    width: 1.75rem;
    transform: rotate(-45deg) translate(1px, -2px);
  }

  /*
* Make this absolute positioned
* at the top left of the screen
*/
  #menu {
    position: fixed;
    width: 80vw;
    /* opacity: 0; */
    height: 100vh;
    max-height: 100vh !important;
    box-shadow: 0px 4px 8px gray;
    margin-left: -2rem;
    padding: 4rem 2rem;
    top: 0;
    background: #ffffff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.4s ease-in-out;
  }

  #menu li {
    padding: 6vw 0vw 6vw 8vw;
    /* background-color: red; */
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    /* font-size: 7vw; */
    text-align: left;
    font-family: "Geomatrix-bolder", sans-serif;
    font-weight: 600;
    /* border-bottom: 1px solid #c1c1c1; */
  }

  .Sub-Services {
    padding: 2vw 0vw 2.5vw 1vw !important;
    /* background-color: whitesmoke; */
    display: flex !important;
    /* flex-direction: colum; */
    justify-content: flex-start !important;
    width: 40vw;
    align-items: center !important;
    text-align: center !important;
    font-size: 6vw !important;
    text-align: left;
    font-family: "Geomatrix-bolder", sans-serif;
    font-weight: 600;
    margin-left: 12vw;
  }

  .Mobile-Web {
    font-size: 0vw !important;
  }

  .Sub-Services img {
    width: 10vw;
    margin-left: 5vw;
    /* margin-left: auto;
    margin-right: auto; */
  }

  .Sub-Services p {
    margin-left: -2vw;
    /* 
    display: flex;
    margin-right: auto; */
  }

  .Sub-Services p a {
    font-size: 3.5vw !important;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }
  .Social-Media-Links {
    /* background-color: black !important;  */
    padding: 0vw 0vw 0vw 0vw !important;
    margin-top: auto !important;
    height: 18%;
    /* margin-top: 10vw !important; */
    display: flex !important;
    /* background-color: red !important; */
    justify-content: space-around !important;
    align-items: center;
    text-align: center;
  }
  .Social-Media-Links a img {
    width: 10vw;
    margin-top: auto !important;
  }

  .HomeContainer {
    padding-top: 28vw;
    margin: unset;
  }
  .aboutContent p {
    font-weight: 500;
    line-height: 5.2vw;
    color: rgba(60, 60, 60, 1);
    font-family: "Geomatrix-Medium", sans-serif;
    font-size: 4.3vw;
  }
  .HomeContainer .Quotation {
    width: 100vw;
  }
  #video {
    width: 70vw;
  }
  .Quotation h1 {
    font-size: 6.5vw;
    line-height: 10vw;
  }
  .HomeContainer {
    flex-direction: column;
    background-image: unset;
  }
  .HomeABout {
    flex-direction: column;
  }
  .HomeABout .aboutImg {
    width: 80%;
  }

  .aboutImg img {
    width: 100vw;
  }
  .About-mobile-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 9vw 0vw 2vw 0vw;
    color: rgba(0, 108, 113, 1);
  }
  .aboutContent h1 {
    display: none;
  }
  .aboutContent {
    display: flex;
    justify-content: justify;
    align-items: left;
    text-align: left;
    width: 82%;
    flex-direction: column;
    text-align: justify;
    padding: unset !important;
    margin-top: 7vw;
  }
  button {
    width: fit-content;
    font-size: 4vw;
    margin-top: 3.5vw;
    padding: 0.5rem 1.2rem;
    border-radius: 36vw;
    border: none;
    background-color: #182232;
    color: white;
    cursor: pointer;
    transition: 0.2s ease;
    font-family: "Geomatrix-Medium";
  }
  .HomeServices {
    margin-top: 15vw;
  }
  .HomeServices header p,
  .HomeTech header p {
    font-size: 5.8vw;
  }
  .HomeServices header h1,
  .HomeTech header h1 {
    font-size: 9vw;
  }
  .serviceImages {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .HomeServices .box {
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    justify-content: center;
    align-items: center;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem 0.5rem;
    border-radius: 2.4vw;
    margin: 4vw 6vw;
    /* background-color: whitesmoke; */
    width: 51vw;
    box-shadow: 1px 1px 100px 1px rgb(0 0 0 / 13%);
    border-top: 5px solid #182232;
  }

  .HomeServices .box img {
    width: 18vw;
  }

  .box p {
    font-size: 4.2vw;
    font-weight: 600;
  }

  .box .link {
    font-size: 2.5vw;
    color: #006c71;
    margin-top: 0.2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-self: center;
  }

  .box .link img {
    width: 3vw !important;
    margin-left: 1.5vw;
  }

  .techImg {
    flex-direction: column;
  }

  .techImg img {
    width: 100vw;
  }
  .techImg .tech-content {
    width: 80vw;
    text-align: justify;
    padding: 5vw 2vw;
    line-height: 5.5vw;
    font-size: 4.3vw;
  }
  .blogImagecontainer {
    flex-direction: column;
  }
  .blogBox {
    width: 80vw;
    border: 2vw;
    box-shadow: 2px 2px 100px 4px rgb(0 0 0 / 25%);
    /* border-radius: 4vw; 
  */
    border-top-right-radius: 4vw;
    border-top-left-radius: 4vw;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    margin: 5vw 0vw;
  }
  .blogImg {
    width: 100vw;
  }

  .blogBox img {
    border-top-right-radius: 2vw;
    border-top-left-radius: 2vw;
  }
  .HomeBlog header h1 {
    font-size: 4vw;
  }

  .time {
    padding: 0vw 2.5vw;
  }
  .content {
    padding: 2vw 3.5vw;
  }

  .content h1 {
    font-size: 4.8vw;
  }

  .content p {
    font-size: 2.5vw;
  }

  .content .link {
    font-size: 2.8vw;
  }

  .HomeClients header h1 {
    font-size: 4.5vw;
  }

  .imageContainer .imageBox {
    margin-top: 5vw;
    /* border: 1px solid gray; */
    border-radius: 0.5vw;
    width: 40vw;
    margin-bottom: 10vw;
    padding: 0.4vw;
  }

  .imageContainer {
    flex-wrap: wrap;
    filter: blur(0px);
  }

  .imageContainer .imageBox img {
    width: 100vw;
    filter: blur(0px);
  }
}
