.Projects {
  filter: blur(0px);
  -webkit-filter: blur(0px);
}

.ProjectsBlur {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.Projects header,
.ProjectsBlur header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 3vw 8vw 0vw;
}

.Projects header h1,
.ProjectsBlur header h1 {
  font-size: 6vw;
  margin-bottom: auto;
  margin-left: -7vw;
  margin-top: 2.5vw;
}
.ProjectsCards {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 5vw 0vw;
}
.ProjectsCards .Card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  /* border: 1px solid black; */
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.25);
  width: 25vw;
  max-width: 28vw !important;
  border-radius: 1vw;
  padding: 2vw;
  margin: 2.5vw 2vw;
  cursor: pointer;
}
.ProjectsCards .Card img {
  padding: 2vw 0vw;
  height: 10vw;
}
.ProjectsCards .Card h1 {
  font-size: 2vw;
  padding: 0.4vw 0vw;
}
.ProjectsCards .Card p {
  color: rgba(60, 60, 60, 1);
  font-size: 1vw;
  font-weight: 500;
}
.Link {
  color: rgba(0, 108, 113, 1);
  /* padding: 0.5vw 0vw; */
}

.modalSection {
  /* background-image: url("../Assets//Images/projectModalbackground.svg"); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: 26vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 1vw;
  padding: 1.2vw 3.5vw 1vw;
  /* background-color: red !important; */
}
.ModalContent header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* margin-top: -1.6vw; */
  padding-bottom: 2vw;
}
.ModalContent header img {
  margin-right: -19vw;
}
.ModalContent header h1 {
  font-size: 3vw;
  color: rgba(24, 34, 50, 1);
}
.ModalContent .BottomBox .box-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.BottomBox .box-container .box {
  width: 18vw;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
  padding: 0.2vw 0.8vw;
}

.Product-box {
  width: 18vw;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
  padding: 0.2vw 0.8vw;
  margin-bottom: 2vw;
}
.Product-box img {
  width: 3vw;
}
.Product-box p,
.BottomBox .box-container .box p {
  color: rgba(89, 90, 92, 1);
  font-weight: 500;
  font-size: 0.8vw;
}
.Product-box h1,
.BottomBox .box-container .box h1 {
  font-size: 1.2vw;
  padding: 0.5vw 0vw;
}
.techImage {
  padding-bottom: 1.1vw;
  margin-top: 8vw;
}
.techImage div {
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  width: 10vw;
  height: 10vw;
  padding: 2vw;
  border-radius: 100vw;
}
.techImage .tech-One {
  margin-left: -30vw;
  width: 20vw;
  border-radius: 100vw;
  z-index: 999;
}
.tech-One img {
  width: 13vw !important;
}

.techImage .tech-Two {
  margin-left: -23vw;
  width: 20vw;
  border-radius: 100vw;
  margin-top: -0.5vw;
}
.tech-Two img {
  width: 9vw !important;
}

.techImage .tech-Three {
  margin-left: -22vw;
  width: 16vw;
  border-radius: 100vw;
  margin-top: -2.2vw;
}

.tech-Three img {
  width: 7vw !important;
}
.WebsiteLink {
  display: flex;
  /* background-color: red; */
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 1vw 0.4vw;
}
.WebsiteLink a {
  text-decoration: underline;
  padding: 0vw 0.4vw;
  color: rgba(0, 0, 0, 1);
  font-size: 1vw;
  font-weight: 500;
  /* margin-left: 1vw; */
}

@media screen and (max-width: 768px) {
  .Projects {
    padding-top: 25vw;
  }
  .Projects header img {
    width: 73vw;
  }
  .ProjectsCards .Card {
    border-radius: 2vw;
    box-shadow: 4px 4px 60px rgb(0 0 0 / 8%);
  }

  .Projects header,
  .ProjectsBlur header {
    padding: unset;
    display: flex;
    justify-content: center;
  }
  .Projects header h1,
  .ProjectsBlur header h1 {
    margin-left: -13vw;
    font-family: 'Geomatrix-Medium';
    font-size: 7vw;
  }

  .ProjectsCards .Card {
    width: 42vw;
    max-width: 42vw !important;
  }
  .ProjectsCards .Card h1 {
    font-size: 4vw;
    font-family: 'Geomatrix-Bold';
    padding-bottom: 1.5vw;
  }
  .ProjectsCards .Card p {
    font-size: 2vw;
    font-family: 'Geomatrix-Medium';
    text-align: justify;
    padding: 0vw 0.5vw;
    }
  .ProjectsCards .Card img {
    height: 12vw;
    margin: 3vw 0vw;
  }

  .modalSection {
    /* background-size: 40vw !important; */
    background-image: unset;
  }

  .ModalContent header h1 {
    font-size: 6vw;
  }

  .ModalContent header img {
    margin-right: -39vw;
    width: 5vw;
  }

  .Product-box img {
    width: 5.5vw;
  }
  .Product-box {
    width: 25vw;
  }

  .Product-box h1,
  .BottomBox .box-container .box h1 {
    font-size: 3vw;
    padding: 0.5vw 0vw;
  }

  .Product-box p,
  .BottomBox .box-container .box p {
    color: rgba(89, 90, 92, 1);
    font-weight: 500;
    font-size: 1.8vw;
  }

  .css-13m60ms {
    width: 85vw !important;
    /* height: 80vh !important; */
  }

  .Pop-up {
    /* background-color: red; */
    padding: 2vw 2vw;
    /* border-radius: 2vw; */
  }
  .Pop-up header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .Pop-up header h1 {
    font-size: 5vw;
  }
  .Pop-up header img {
    width: 7vw;
  }
  .Target {
    /* width: vw; */
    /* background-color: red; */
  }

  .Product,
  .Target,
  .useCase {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    /* margin-top: 2vw; */
    /* background-color: red; */
  }

  .Product header,
  .Target header,
  .useCase header {
    margin: 1.2vw 0vw;
  }
  .Product p,
  .Target p,
  .useCase p {
    margin-top: auto;
    margin-left: 0.8vw;
    font-size: 3vw;
    font-weight: 600;
  }
  
  .Product img,
  .Target img,
  .useCase img {
    width: 6vw;
  }

  .Product .content p,
  .Target .content p,
  .useCase .content p {
    font-size: 1.8vw;
    font-weight: 400;
  }
  .Bottom-Content {
    display: flex;
    justify-content: center;
    margin-top: 5vw;
    align-items: center;
    text-align: left;
  }

  .social-Links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .social-Links img {
    width: 15vw;
    margin: 2vw 3vw;
  }

  .Website-link p {
    font-size: 2.5vw;
    font-weight: 500;
  }
}
