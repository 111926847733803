/* p{
    font-weight: 400;
} */
.High-Lights {
  color: #182232;
  font-weight: bolder;
  font-size: 1.2vw;
  padding: 0vw 0.5vw;
}

.Blog-Cards-Container {
  margin: 2.5vw;
  /* border: 1px solid rgb(200, 199, 199); */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.Blog-Cards-Container .Blog-Cards {
  box-shadow: 2px 2px 100px 4px rgb(0 0 0 / 10%);
  border-radius: 2.5vw;
  margin: 2vw 0vw;
}

.Full-Content-Container {
  padding: 0vw 6vw;
}
/* .Blog-Cards .Tag-line {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Geomatrix-Bold";
  font-weight: 700;
  font-size: 3.5vw;
  color: black;
  background-color: rgb(195, 195, 195);
  border-top-right-radius: 2.5vw;
  border-top-left-radius: 2.5vw;
  padding: 6vw 0vw;
} */

.Time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: rgb(101, 99, 99);
  font-family: "Geomatrix-Medium";
  padding-left: 4vw;
}
.Month {
  /* background-color: red; */
  width: fit-content;
  padding: 0vw 1.2vw;
  border-right: 2px solid rgb(101, 99, 99);
}

.Year {
  padding: 1vw;
}

.blog .heading {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  text-align: left;
}
.blog .heading h1 {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  /* color:  red; */
  padding: 1.5vw 0vw;
}
.blog .heading h1 h2 {
  padding: 0vw 0.5vw;
  font-size: 2vw;
}

.Defination {
  display: flex;
  /* background-color: red; */
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  padding: 0vw 0vw 3vw 0vw;
}
.Defination p span {
  font-size: 1.5vw;
  font-weight: 500;
  color: #182232;
}

.Sub-heading {
  padding: 0.5vw 0vw;
  font-size: 2vw;
  font-weight: 500;
  color: #182232;
  display: flex;
  margin-right: auto;
}

.Full-Content-Container li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 0.6vw 0vw;
  font-size: 1.2vw;
}
.cloud-points {
  padding: 0.5vw 2.2vw;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  flex-direction: column;
}
.cloud-points p {
  padding: 0.5vw 0vw;
}
.cloud-points li h2 {
  font-size: 1.2vw;
  padding: 0vw 0.4vw;
}

.conclusion {
  color: black;
  padding: 1vw 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.Why-use header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.Why-use header h1 {
  margin-right: auto;
  font-size: 2.5vw;
  padding: 1.2vw 1vw;
  font-family: "Geomatrix-Bold";
}

.cloud-2023 {
  padding: 2.5vw 0vw;
  /* font-size: 2vw; */
}
.cloud-2023 span {
  font-size: 1.8vw;
  font-family: "Geomatrix-Bold", sans-serif;
}

.Current-Target {
  padding: 4vw 0vw;
}
.Current-Target header h1 {
  font-size: 2vw;
  font-family: "Geomatrix-Medium";
  padding: 1.2vw 0vw 1.5vw 0vw;
  display: flex;
  margin-right: auto;
}

.Current-Target .content {
  /* display: flex; */
  text-align: justify !important;
}

/* +================================= React js Blog Editing from here =========================================== */

.react-js p {
  font-size: 1.2vw !important;
}

.react-js .header,
.Tag-line {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: #182232;
  border-top-right-radius: 2.5vw;
  border-top-left-radius: 2.5vw;
  padding: 5vw 0vw;
}
.Tag-line h1 {
  font-size: 5vw;
  color: white;
  font-family: "Geomatrix-Bold", sans-serif;
}
.react-js .header h1 {
  font-size: 9vw;
  color: white;
  font-family: "Geomatrix-Bold", sans-serif;
}
.react-js .header img,
.Tag-line img {
  width: 18vw;
}

.point-heading {
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: justify;
  flex-direction: column;
}
.point-heading h1 {
  font-size: 1.3vw !important;
  font-weight: bolder;
  font-family: "Geomatrix-Bold", sans-serif;
  padding: 2vw 0vw 0.4vw 0vw;
}
.react-Sub-heading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.react-Sub-heading h1 {
  font-family: "Geomatrix-Bold", sans-serif;
  font-size: 2vw;
  color: #182232;
}

.human-holding-react-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 80vw;
  /* background-color: red; */
}

.human-holding-react-img img {
  width: 25vw;
  border-radius: 5vw;
}

.human-holding-react-content header {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: white;
  margin-right: auto;
  padding: 3vw 0vw 1.2vw 0vw;
}
.human-holding-react-content header h1 {
  font-family: "Geomatrix-Bold", sans-serif;
  font-size: 2vw;
}

.react-img-content img {
  width: 70vw;
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  box-shadow: 3px 1px 6px gray;
}

.react-img-content p {
  text-align: justify;
  padding: 3vw 0vw 1vw 0vw;
  font-size: 1.2vw;
}

.react-conclusion {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
  width: 80vw;
  padding: 2vw 0vw;
}

/* node js blog editing from here ..... */

.nodejs-feature header {
  display: flex;
  justify-content: flex-start;
}

.nodejs-feature header h1 {
  color: #182232;
  font-size: 2.5vw;
  font-family: "Geomatrix-Bold";
  padding: 0vw 0vw 1.5vw 0vw;
}

.node-js-points {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: justify;
}
.node-js-points div {
  padding: 0.5vw 0vw;
}

.node-img-content {
  display: flex;
  width: 80vw;
  padding: 2.5vw 0vw !important;
  /* background-color: red; */
  justify-content: center;
  /* padding: 200vw 0vw; */
}

.node-img-content img {
  /* background-color: red; */
  width: 70vw;
  border-top-left-radius: 2vw;
  border-top-right-radius: 2vw;
  box-shadow: 3px 1px 6px gray;
}
.nodejs-conclusion{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    text-align: left;
    padding: 3.5vw 0vw;
}
/* mobile responsive code here ....... */
@media screen and (max-width: 768px) {
  #Blog {
    padding-top: 20vw;
  }
  .blog .heading h1 {
    font-size: 2.6vw;
  }
  .High-Lights {
    font-size: 3.6vw !important;
  }

  .Defination {
    font-size: 3.6vw;
    text-align: justify;
  }

  .Sub-heading {
    font-size: 6vw;
    padding: 6vw 0vw;
  }

  .Why-use header h1 {
    padding: 5.2vw 1vw;
    font-size: 5.5vw;
  }

  .cloud-points p {
    padding: 2.5vw 0vw;
  }

  .Current-Target header h1 {
    font-size: 4.5vw;
    font-family: "Geomatrix-Medium";
    padding: 3.2vw 0vw 4.5vw 0vw;
    display: flex;
    margin-right: auto;
  }

  .cloud-2023 span {
    text-align: justify !important;
    display: flex;
    padding: 3.5vw 0vw;
  }

  /* react js blog */

  .react-js .Month {
    font-size: 3vw !important;
  }

  .react-js .Year {
    font-size: 3vw !important;
  }

  .react-js p {
    font-size: 3vw !important;
  }

  .react-Sub-heading h1 {
    font-size: 5vw;
  }

  .point-heading h1 {
    font-size: 3vw !important;
  }

  .human-holding-react-content header h1 {
    font-size: 4vw;
    padding: 2vw 0vw;
  }

  .Blog-Cards-Container .Blog-Cards {
    box-shadow: 2px 2px 100px 5px rgb(0 0 0 / 25%);
    border-radius: 2.5vw;
    margin: 5vw 0vw;
  }

  .nodejs-conclusion p{
    
  }





}
