.Error {
  /* background-color: red; */
  display: flex;
  /* justify-content: space-evenly; */
  justify-content:center;
  align-items: center;
  text-align: center;
  padding-top: 2vw;
}

.oscar-img{
    background-color: white;
    /* border: 1px solid black; */
}

.oscar-img img {
  width: 30vw;

}


.error-content{
/* background-color:orange; */
/* height: 30vw; */
margin-left: -2vw;
}
.error-content p{
    font-size: 3.5vw;
    font-weight: bolder;
    color:  black;
    word-spacing: 0.2vw;

}

.error-content p .Oscar{
    font-size: 3.5vw;
    color:rgba(0, 108, 113, 1);
}
.error-content p .Since{
    font-size: 1vw;
    font-weight: 500;
}

.page-not-found{
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
    margin-top: 2vw;
}
.page-not-found h1{
    font-size: 14vw;
    display: flex;
    color:  rgba(24, 34, 50, 1);
    margin-bottom: -5vw;
    margin-right: auto;
}
.page-not-found p{
    display: flex;
    justify-content: center;
    margin-right: 7vw;
    /* margin-right: auto; */
    font-size: 1.5vw;
    color:  black;
}

.find-path{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}
.find-path p{
    display: flex;
    margin-right: 40vw;
    font-size: 1.6vw;
    color: black;
}
.find-path img{
    margin: -10vw 0vw;
    /* border: 1px solid beige; */
}