.WebService header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
}
.WebContent {
  width: 48vw;
  padding: 0vw 5.5vw 0vw 2vw;
}
.WebContent h1 {
  font-size: 3.6vw;
  color: rgba(24, 34, 50, 1);
  padding: 1vw 0vw;
}
.WebContentImage {
  padding: 3vw 3vw;
}
.WebContentImage img {
  width: 30vw;
}
.WhatWeDo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5vw 0vw;
  text-align: center;
}
.whatWeDoImage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 47vw;
  background-image: url("../Assets/Images/ServiceWhatWeDo.svg");
  /* background-color: red; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 40vw;
  color:  black !important;
}
.whatWeDoImage h1 {
  font-size: 3.5vw;
  padding-right: 5.5vw;
}
.WhatWeDoCards {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color:  white !important;
}
.WhatWeDoCards .card {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: left;
  width: 45vw;
  background-color: rgba(24, 34, 50, 1);
  /* color: white; */
  cursor: pointer;
  /* border: 1px solid red; */
  border-radius: 50vw;
  padding: 1vw 2.5vw;
  margin: 2vw 0vw;
}
.WhatWeDoCards .card:hover{
  background-color: white;
  /* color: rgba(24, 34, 50, 1); */
  transition-duration: 0.3s;
  border: 0.5px solid rgba(24, 34, 50, 0.693);
  color:  black  !important;
}

.WhatWeDoCards .card:hover  .card h1{
  color: black;
}

.WhatWeDoCards .card:hover .WhatWeDoCards .card .CardContent {
  border-right: 1px solid black;
}

.WhatWeDoCards .card .CardContent {
  /* background-color: red; */
  /* width: 80vw; */
  padding: 0vw 0.5vw;
  border-right: 1px solid rgba(255, 255, 255, 1);
}
.WhatWeDoCards .card h1 {
  font-size: 1vw;
  padding-bottom: 0.4vw;
  /* color: white; */
}



.WhatWeDoCards .card p {
  font-size: 0.8vw;
}
.CardImage {
  /* background-color: white; */
  /* margin: 1vw; */
  margin-left: 1.5vw;
}
.CardImage img {
  width: 5vw;
}

.WhatWeDoCards .CardOne {
  margin-left: -18vw;
  /* background-color: red; */
}
.WhatWeDoCards .CardTwo {
  margin-left: -5vw;
}

.WhatWeDoCards .CardThree {
  margin-left: -3vw;
  margin-top: 5vw;
  margin-bottom: -2vw;
}

.WhatWeDoCards .cardFour {
  /* margin-bottom: -3vw; */
  margin-top: 5vw;
  margin-left: -15vw !important;
}

.GetQuote {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3vw;
}



.Web-service-mobile-heading{
  display: none;
}
.mobile-WebContentImage{
  display: none;
}

@media screen  and (max-width : 768px){
  .WebService{
    padding-top: 20vw;
  }
  .Web-service-mobile-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 0vw;
    text-align: center;
  }
  .Web-service-mobile-heading h1{
    font-size: 8.5vw;
    color: rgba(24, 34, 50, 1);
    padding: 3vw 0vw 0vw 0vw;
  }
  .WebContent h1{
    display: none;
  }
  .WebContentImage{
    display:none;
  }
  .mobile-WebContentImage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
  }
 
  .WebService header{
    flex-direction: column;
  }
  .WebContent{
    width: 90vw;
    text-align: justify;
    padding-top: 5vw;
  }

  .whatWeDoImage{
    display: none;
  }
  .WhatWeDoCards {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    /* background: red; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 38vw;
    background-image: url("../Assets//Images/snakeImageWeb.svg");
    width: 100vw;
    /* background-color: red; */
    height: 150vw;
    padding: 12vw 3vw;
}
.WhatWeDoCards .CardOne {
  /* margin-left: -18vw; */
  /* background-color: red; */
  margin-left: auto;
  margin-top: 8vw;

}

.WhatWeDoCards .CardTwo {
  margin-left: 0vw;
  margin-right: auto;
  margin-top: 14.2vw;

}

.WhatWeDoCards .CardThree {
  margin-left: 0vw;
  /* margin-top: 5vw; */
  margin-bottom: 0vw;
  margin-left: auto;
  margin-top: 14vw;
}

.WhatWeDoCards .cardFour {
  margin-bottom: 0vw;
  margin-top: 16vw;
  margin-left: 0vw !important;
  margin-right: auto;

}

.WhatWeDoCards .card h1{
  font-size: 2.5vw;
  /* color: white !important; */
  font-family: "Geomatrix-Bold",sans-serif;
}

.WhatWeDoCards .card p{
  font-size: 1.4vw;
}

.CardImage img{
  width: 12vw;
}

.WhatWeDoCards .card{
  width: 60vw;
  padding: 2vw 3vw;
}

.WhatWeDoCards .card{
  width: 58vw;
  padding: 2vw 4vw;
}
.GetQuote button{
  font-size: 4vw;
  padding: 1.8vw 3vw;
  font-family: "Geomatrix-Medium";
}

}