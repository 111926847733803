
#form {
  width: 45vw;
  /* border: 1px solid black; */
}
.Contact{
    display: flex;
    justify-content: space-around;
    margin-top: 4vw;
}

.column {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.row div {
  margin: 0.2vw 0vw;
}
.row div input {
  border: none;
  border-bottom: 1.8px solid gray;
  width: 40vw;
  padding: 0.3vw 0.6vw;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.row div li {
  font-size: 1.5vw;
  margin-top: 1.8vw;
}

input:focus {
  outline: none;
  border: none;
}
.Submitbtn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 3.5vw;
}
.Submitbtn li{
  color: red;
}


.Heading {
  /* width: 50vw; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-top: 2.5vw;
  margin-left: 4.5vw;
}
.Heading h1 {
  font-size: 2.6vw;
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(90deg, #579d9f, #121729 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #006C71;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 80%;
  width: 45vw;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}

/* media queries for mobile devices */
@media screen and (max-width: 768px){
  .Heading{
    margin: unset;
    padding-top: 25vw;
  }
  .Heading h1 {
    font-size: 7.5vw;
    font-family: "Geomatrix-Bold", sans-serif;
    background: linear-gradient(90deg, #579d9f, #121729 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 4vw;
  }
  .Contact{
    margin: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
   #form{
    width: 100vw;
   }
   .row div li {
    font-size: 5.5vw;
    margin-top: 9vw;
    font-family: 'Geomatrix-Bold';
   }

   .row div input{
    width: 85vw;
    padding: 2.2vw 0vw;
    border-radius: unset !important;
    font-size: 3.2vw;
    font-family: "Geomatrix-Light";
   }
   .mapouter {
    position: relative;
    text-align: right;
    height: 80%;
    width: 92vw;
    margin: 4vw 0vw;
}
.Submitbtn li{
  color: red;
  font-family: 'Geomatrix-Medium';
  font-size: 4vw;
}
   
}