/* Previous One Footer... */


.Footer {
  background-color: #182232;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2vw 5vw;
}
.copyright{
  background-color: #182232;
  text-align: center;
  color: #fff;
  padding: .5rem 0;
}
.socailMediaLinksMobile{
  display: none;
}
.Footer .Links{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    text-align: left;
}

.socailMedia {
  width: 40vw;
  max-width: 40vw;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  flex-direction: column;
}
.socailMedia p {
  font-size: 1vw;
  margin: 2.5vw 0vw;
  width: 80%;
}
.socailMediaLinks {
  display: flex;
  width: 100%;
}
.socailMediaLinks a {
  margin: 0vw 2vw;
}
.socailMediaLinks a img {
  width: 2vw;
}
.CapNxtLogo {
  width: 15vw;
  margin-top: 1vw;
}
.Links .Navlinks{
    width: 25vw;
    display: flex;
    flex-direction: column;
    max-width: 25vw;
    margin-bottom: auto;
}
.Links .Navlinks h1{
    font-size: 2vw;
    margin-bottom: 1vw;
    color: #fff;
}
.Links .Navlinks p{
    font-size: 1.1vw;
    margin: 0.5vw 0vw;
    color: white;
    cursor: pointer;
    width: fit-content;
}
.Links .Navlinks p a{
  color: white;
}
.Links .Navlinks p a:hover{
    color: #006C71;
    transition-duration: 0.2s;
}
.ContactDetails{
    width: 30vw;
    max-width: 30vw;
}
.contactLinks{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: baseline;
    text-align: left;
}
.contactLinks div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.contactLinks div img{
    width: 1.4vw;
    margin-right: 0.8vw;
}

.contactLinks div{
    margin: 1.2vw 0vw;
}
.contactLinks div span{
    font-size: 1vw;
}
.contactLinks h1{
    font-size: 2vw;
    margin-bottom: 0.2vw;
    color: #fff;
}

.link:hover{
  color: #006C71;
  cursor: pointer;
}





 @media screen and (max-width : 768px){
  .Footer {
    flex-direction: column;
  }
  
.socailMedia {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.socailMedia p{
  font-size: 3.2vw;
  display: flex;
  text-align: left;
}
.CapNxtLogo{
  width: 45vw;
  margin: 8vw 0vw;
}
.Links .Navlinks{
  display: none;
}

.ContactDetails {
  margin-top: 5vw;
  width: 80vw;
  max-width: 80vw;
  display: flex;
  justify-content: center;
  margin-top: 10vw;
}
.contactLinks h1{
  font-size: 5vw;
  margin: 0vw auto;
}
.contactLinks div img{
  width: 6vw;
}
.contactLinks div span{
 margin-left: 1.2vw;
 font-size: 3.2vw;
 font-family: "Geomatrix-Medium";
}
.contactLinks div {
  margin: 2.5vw 0vw 1vw 0vw;
}
.socailMediaLinks{
  display: none;
}
.socailMediaLinksMobile{
  display: flex;
  justify-content: space-around;
  width: 80vw;
  margin: 14vw 0vw 4vw 0vw;
}
.socailMediaLinksMobile a img{
  width: 10vw;
}


.socailMedia p{
  display: none;
}
.CapNxtLogo{
  margin: 8vw 25vw 0vw 0vw;
}


.contactLinks h1{
  margin: unset;

}

 }




 /* New One Footer  */


 .mobile-Footer{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #182232;
  flex-direction: column;
  /* padding: 5vw; */
 }
 .mobile-Footer div{
  padding: 1.5vw 0vw;
 }
 .mobile-Footer .Logo{
  width: 60vw;
 }
.social-copyright{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

}

 .mobile-Footer .Footer-social-link{
  /* background-color: white; */
  width:  40vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
 }
 .mobile-Footer .Footer-social-link img{
  width: 7.5vw;
 }
 .copy-right{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
 }
 .copy-right p{
  color: white;
 }