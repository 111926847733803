@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;600;700;800&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Poppins&display=swap");

@font-face {
  font-family: "Geomatrix-Light";
  src: url("../src/Assets/fonts/geometrix/GeomatrixLight-9Yrm2.ttf");
}

@font-face {
  font-family: "Geomatrix-Bold";
  src: url("../src/Assets/fonts/geometrix/GeomatrixBold-6YBZ1.ttf");
}

@font-face {
  font-family: "Geomatrix-Medium";
  src: url("../src/Assets/fonts/geometrix/GeomatrixMedium-1G4Zv.ttf");
}

@font-face {
  font-family: "Geomatrix-Thin";
  src: url("../src/Assets/fonts/geometrix/GeomatrixThin-BWPYw.ttf");
}

@font-face {
  font-family: "Geomatrix-Normal";
  src: url("../src/Assets/fonts/geometrix/Geomatrix-8MYjn.ttf");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --header-height: 0;

  /*========== Colors ==========*/

  /* HSL color mode */
  --text-color: #3c3c3c;
  --title-color: #182232;
  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;

  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
  --big-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.75rem;
  --smaller-font-size: 0.5rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.9rem;
    --smaller-font-size: 0.813rem;
  }
}

/* ==== BASE ==== */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

/* RESUSABLE CLASSES */
/* h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: 500;
}

h1 {
  color: var(--title-color);
  font-weight: 600;
} */

ul {
  list-style: none;
}

li {
  list-style-type: none;
}

a {
  color: #000;
  text-decoration: none;
}
a:hover{
  color: #006C71;
}
.active a{
  color: #006C71;
}
a:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

button {
  width: fit-content;
  font-size: 1vw;
  /* margin-top: 1.5vw; */
  padding: 1rem 2rem;
  border-radius: 50vw;
  border: none;
  background-color: #182232;
  color: white;
  cursor: pointer;
  transition: .2s ease;
}

button:hover{
  border: 1px solid #ababab;
  background-color: #fff;
  color: #182232;
}

@media screen and (max-width:768px) {
  button{
    font-size: .9vw;
    padding: 1rem 2.2rem;
  }
}